import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {Dispatch, SetStateAction} from "react";
import {PhotoProvider, PhotoView} from "react-photo-view";
import {Fab, Link} from "@mui/material";
import {EmailOutlined} from "@mui/icons-material";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: '80%',
    // height: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
    width: {
        xs: 1, // theme.breakpoints.up('xs')
        sm: '80%', // theme.breakpoints.up('sm')
        md: '50%', // theme.breakpoints.up('md')
        lg: '40%', // theme.breakpoints.up('lg')
        xl: '30%', // theme.breakpoints.up('xl')
    },
    height: {
        mobile: '100%'
    }
};


const images = require.context("../../public/images", true)

function GetImage({imageName, index}: { imageName: string, index: number }) {
    try {
        const correctImageName = `./${imageName}`
        let image = images(`${correctImageName}`)
        return (
            <PhotoView key={index} src={image}>
                <img src={image} alt={imageName}
                     style={{ width: 100, height: 60 }}
                ></img>
            </PhotoView>
        )
    } catch (e) { }
    return (<div></div>)
}


export default function ModalDetailColiving({colivingData, open, setOpen}: {colivingData: any, open: boolean; setOpen: Dispatch<SetStateAction<boolean>>; }) {
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <Typography id="modal-modal-title" variant="h4">
                        {colivingData.name}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        From ${colivingData.price} per month
                    </Typography>

                    <div  style={{padding: '10px'}}>
                        <PhotoProvider>
                            {(colivingData.pictures as string).split(",").map((imageName, index) => {
                                return (
                                    <GetImage imageName={imageName} index={index}/>
                                )
                            })}
                        </PhotoProvider>
                    </div>
                    <Typography  component="h2">
                        {colivingData.address}
                    </Typography>
                    <Link href={colivingData.website} target="_blank" rel="noopener">
                        {"Website"}
                    </Link>
                    <Box sx={{ p: 2,
                        width: '90%',
                        height: 450 }}
                        dangerouslySetInnerHTML={{ __html: colivingData.googleMapIframe }}>
                    </Box>

                    <Fab variant="extended" color="primary" aria-label="add"
                        href={ "https://tally.so/r/31W2W4?id=" + colivingData.id + "&name=" + colivingData.name }
                         target="_blank"
                    >
                        <EmailOutlined sx={{ mr: 1 }} />
                        Contribute
                    </Fab>
                </Box>
            </Modal>
        </div>
    );
}