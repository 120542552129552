import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {CardActionArea, Fab} from '@mui/material';

import placeholder from "./placeholder.png"
import ModalDetailColiving from "./ModalDetailColiving";
import {EmailOutlined} from "@mui/icons-material";

const images = require.context("../../public/images", true)

export default function ColivingCard({colivingData}: { colivingData: any }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    let image: any
    try {
        const imageName = colivingData.pictures.split(",")[0] ?? ""
        const correctImageName = `./${imageName}`
        image = images(`${correctImageName}`)
    } catch (e) {
        image = placeholder
    }

    return (
        <Card sx={{m:2, borderRadius: 5, boxShadow: 10 }}>
            <CardActionArea onClick={handleOpen}>
                <CardMedia
                    component="img"
                    height="240"
                    image={image}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {colivingData.name}
                    </Typography>
                    <Typography gutterBottom variant="subtitle1" component="div">
                        {colivingData.city}, {colivingData.country}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        From ${colivingData.price} per month
                    </Typography>
                </CardContent>

            </CardActionArea>
            <ModalDetailColiving colivingData={colivingData} open={open} setOpen={setOpen}/>

        </Card>
    );
}